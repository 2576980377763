import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Jumbotron from "react-bootstrap/Jumbotron"
import Card from "react-bootstrap/Card"

export const data = graphql`
  query {
    allContestDataset {
      edges {
        node {
          Description
          FAQ
          FeaturedImageURL
          Id
          Rules
          SlugURL
          Title
          id
        }
      }
    }
  }
`

export const seo = {
  title: "Contests | Crampete",
  description:
    "Participate in Crampete coding contests and sharpen your programming skills. Contests feature exercises in HTML, CSS, JavaScript and other essential web-centric programming languages.",
  keywords: "Crampete, Contests, Quiz",
  image: "",
  url: "https://www.crampete.com/contests",
  author: "Crampete",
}

const Contest = ({ data }) => {
  return (
    <Layout
      title={seo.title}
      description={seo.description}
      image={seo.image}
      keywords={seo.keywords}
      url={seo.url}
      author={seo.author}
    >
      <Jumbotron className="bg-lightblue">
        <div className="container">
          <div
            style={{ maxWidth: "600px", textAlign: "center", margin: "auto" }}
          >
            <h2>Contests</h2>
            <p className="mt-4">
              Sharpen your coding skills with Crampete's weekly contests. Anyone
              can take part; there's no entry fee. Certificates are included.
            </p>
          </div>
        </div>
      </Jumbotron>
      <div className="container">
        <div className="row">
          {data.allContestDataset.edges.map(({ node }) => {
            return (
              <div
                className="col-md-6 col-lg-4 col-xl-4"
                style={{ marginBottom: "20px" }}
              >
                <Card className="cardShadow">
                  <Card.Img variant="top" src={node.FeaturedImageURL} />
                  <Card.Body>
                    <Card.Title>{node.Title}</Card.Title>
                    <Card.Text>
                      {/* <div
            	                  dangerouslySetInnerHTML={{ __html: node.Description }}
            	                /> */}
                    </Card.Text>
                  </Card.Body>
                  <Card.Body>
                    <Link
                      className="btn btn-primary"
                      to={"/contest/" + node.SlugURL + "/"}
                    >
                      Read more
                    </Link>
                  </Card.Body>
                </Card>
              </div>
            )
          })}
        </div>
      </div>
    </Layout>
  )
}

export default Contest
